'use client';

import { useEffect, useState } from 'react';
import { format, subDays, subMonths, subYears } from 'date-fns';
import { cn } from '~/core/utils/shadcn-utils';
import { Button } from '~/core/ui/Button';
import { Calendar } from '~/core/ui/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '~/core/ui/Popover';
import type { DateRange } from 'react-day-picker';
import FilterModal from '~/app/(workspace)/components/FilterModal';
import type { DashbaordFilters } from '~/app/(workspace)/queries';
import TimeTabs from '~/app/(workspace)/components/TimeTabs';
import Trans from '~/core/ui/Trans';
import { CalendarDate, FilterLines } from '@untitled-ui/icons-react';

export default function CustomFilters({
  filters,
  filtersData,
  handleApplyFilters,
  handleRangeChange,
  hideExtraFilters,
}: {
  filters: DashbaordFilters;
  filtersData: {
    campaigns: { label: string; value: string }[];
    agents: { label: string; value: string }[];
  };
  handleApplyFilters: (filters: DashbaordFilters) => void;
  handleRangeChange: (range: '30d' | '3m' | '1y' | 'custom') => void;
  hideExtraFilters?: boolean;
}) {
  const [selectedRange, setSelectedRange] = useState<
    '30d' | '3m' | '1y' | 'custom'
  >('30d');
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: subDays(new Date(), 30),
    to: new Date(),
  });

  const handleLocalRangeChange = (range: '30d' | '3m' | '1y' | 'custom') => {
    setSelectedRange(range);
    handleRangeChange(range);

    if (range === '30d') {
      setDateRange({
        from: subDays(new Date(), 30),
        to: new Date(),
      });
    } else if (range === '3m') {
      setDateRange({
        from: subMonths(new Date(), 3),
        to: new Date(),
      });
    } else if (range === '1y') {
      setDateRange({
        from: subYears(new Date(), 1),
        to: new Date(),
      });
    }
  };

  useEffect(() => {
    if (dateRange) {
      handleApplyFilters({
        ...filters,
        campaigns: filters?.campaigns ?? [],
        agents: filters?.agents ?? [],
        dateRange:
          dateRange?.from && dateRange?.to
            ? {
                from: format(dateRange.from, "yyyy-MM-dd'T'00:00:00"),
                to: format(dateRange.to, "yyyy-MM-dd'T'23:59:59"),
              }
            : { from: null, to: null },
      });
    }
  }, [dateRange]);

  const formatDateRange = () => {
    if (dateRange?.from && dateRange?.to && selectedRange === 'custom') {
      return `${format(dateRange.from, 'MMM d, yyyy')} - ${format(dateRange.to, 'MMM d, yyyy')}`;
    }
    return <Trans i18nKey={'dashboard:customDates'} />;
  };

  return (
    <div className={cn('flex items-center', !hideExtraFilters && 'w-full')}>
      <div className="flex items-center space-x-4">
        <TimeTabs
          selectedRange={selectedRange}
          handleRangeChange={handleLocalRangeChange}
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              size="small"
              className={cn(
                'justify-start text-sm font-medium h-9',
                selectedRange !== 'custom' && 'text-foreground'
              )}
            >
              <CalendarDate className="h-4 w-4" />
              {formatDateRange()}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateRange?.from}
              selected={dateRange}
              onSelect={(newDateRange) => {
                setDateRange(newDateRange);
                setSelectedRange('custom');
                handleRangeChange('custom');
              }}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>

      {!hideExtraFilters && (
        <>
          <div className="flex-grow" />
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline">
                <div className="flex space-x-2 items-center text-foreground font-medium">
                  <FilterLines className="h-4 w-4" style={{ strokeWidth: 2 }} />
                  <span>
                    <Trans i18nKey={'dashboard:filters'} />{' '}
                    {(filters?.agents && filters?.agents?.length > 0) ||
                    (filters?.campaigns && filters?.campaigns?.length > 0) ? (
                      <span>
                        (
                        {(filters?.agents?.length || 0) +
                          (filters?.campaigns?.length || 0)}
                        )
                      </span>
                    ) : null}
                  </span>
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="w-96 !p-0 !border-none"
              align="end"
              side="bottom"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <FilterModal
                campaigns={filtersData.campaigns || []}
                agents={filtersData.agents || []}
                applyFilters={(filters) => {
                  handleApplyFilters(filters);
                }}
                filters={filters}
              />
            </PopoverContent>
          </Popover>
        </>
      )}
    </div>
  );
}
