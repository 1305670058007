import { Tabs, TabsList, TabsTrigger } from '~/core/ui/Tabs';

export default function TimeTabs({
  selectedRange,
  handleRangeChange,
}: {
  selectedRange: '30d' | '3m' | '1y' | 'custom';
  handleRangeChange: (range: '30d' | '3m' | '1y' | 'custom') => void;
}) {
  return (
    <Tabs
      value={selectedRange}
      onValueChange={(value) =>
        handleRangeChange(value as '30d' | '3m' | '1y' | 'custom')
      }
    >
      <TabsList className="h-9">
        <TabsTrigger value="30d">30 days</TabsTrigger>
        <TabsTrigger value="3m">3 months</TabsTrigger>
        <TabsTrigger value="1y">1 year</TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
