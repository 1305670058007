import Button from '~/core/ui/Button';
import { Card, CardContent } from '~/core/ui/Card';
import { Avatar, AvatarFallback } from '~/core/ui/Avatar';
import Trans from '~/core/ui/Trans';
import { AVATARCOLORS } from '~/core/ui/Avatar/AvatarColorsConstants';
import LinkedinLogo from '~/core/ui/Logo/LinkedinLogo';
import { ActivityType, type LatestHotLeadsOutput } from '@topo-io/client';
import Link from 'next/link';
import configuration from '~/configuration';
import { getPath } from '~/core/routes/get-path';
import { formatDate } from 'date-fns/format';
import { Users01 } from '@untitled-ui/icons-react';

const paths = configuration.paths;

const LatestHotLeads = ({
  data,
}: {
  data: LatestHotLeadsOutput[];
}) => {
  return (
    <Card className="w-full h-[456px]">
      <CardContent className="flex flex-col space-y-6 px-6 pt-6 h-full">
        <div className="flex items-center justify-between gap-1 text-card-foreground">
          <span className="text-lg font-semibold flex items-center gap-1.5">
            <Trans i18nKey={'dashboard:latestHotLeadsHeading'} />
          </span>
          <Link
            href={getPath(paths.activityFeed, {
              activity_type: ActivityType.HOT_LEAD,
            })}
          >
            <Button variant="outline">
              <Trans i18nKey={'dashboard:viewMore'} />
            </Button>
          </Link>
        </div>

        <div className="flex flex-col space-y-3 h-full">
          {data.map((lead, index) => (
            <div
              key={lead.email + index.toString()}
              className="flex p-2 space-x-3"
            >
              <div>
                <Avatar className="!w-10 !h-10">
                  <AvatarFallback
                    className={`${AVATARCOLORS[index % AVATARCOLORS.length]} p-4 !text-foreground`}
                  >
                    {(lead.firstName + lead.lastName)
                      .split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-grow">
                <div className="flex flex-col gap-0.5">
                  <div className="text-sm font-medium flex items-center gap-2">
                    <Link
                      href={`${getPath(paths.leads.contacted, {
                        contactId: lead.id,
                        campaignId: lead.campaignId,
                      })}`}
                    >
                      <div className="text-sm font-medium flex items-center gap-2">
                        <span>
                          {lead.firstName} {lead.lastName}
                        </span>
                      </div>
                    </Link>
                    {lead.linkedinUrl ? (
                      <a
                        href={lead.linkedinUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinLogo className="h-[15px] w-[15px] text-primary" />
                      </a>
                    ) : null}
                  </div>
                  <span className="text-xs text-muted-foreground">
                    {lead.jobTitle} ({lead.companyName})
                  </span>
                </div>
                <div className="flex flex-col gap-1 ml-auto whitespace-nowrap">
                  <span className="text-sm text-muted-foreground">
                    {formatDate(lead.timestamp, 'MMM d, yyyy')}
                  </span>
                  <span className="text-xs text-sub-foreground text-right">
                    {formatDate(lead.timestamp, 'h:mm a')}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="text-sm text-sub-foreground flex flex-col justify-center items-center gap-4 h-full">
              <Avatar className="!h-12 !w-12 bg-slate-100 dark:bg-slate-800 flex items-center justify-center text-sidebar-ring">
                <Users01 className="h-6 w-6" />
              </Avatar>
              <Trans i18nKey={'dashboard:noHotLeads'} />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LatestHotLeads;
