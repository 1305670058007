'use client';

import Badge from '~/core/ui/Badge';
import { cn } from '~/core/utils/shadcn-utils';
import type React from 'react';
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from 'react';

import type { KeyboardEvent } from 'react';
import Button from '~/core/ui/Button';
import { Check, X } from '@untitled-ui/icons-react';

type MultiSelectorItemValue = {
  label: string;
  value: string;
  description?: string;
};

type MultiSelectorProps = {
  values: MultiSelectorItemValue[];
  onValuesChange: (value: MultiSelectorItemValue[]) => void;
  loop?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

interface MultiSelectContextProps {
  value: MultiSelectorItemValue[];
  onValueChange: (value: any) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null);

const useMultiSelect = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error('useMultiSelect must be used within MultiSelectProvider');
  }
  return context;
};

const MultiSelector = ({
  values: value,
  onValuesChange: onValueChange,
  loop = false,
  className,
  children,
  dir,
  ...props
}: MultiSelectorProps) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const onValueChangeHandler = useCallback(
    (val: MultiSelectorItemValue) => {
      if (!val) {
        onValueChange([]);
        return;
      }

      const isValueIncluded = value.some((item) => {
        if (Array.isArray(item.value) && Array.isArray(val.value)) {
          return (
            item.value.length === val.value.length &&
            item.value.every((v, i) => v === val.value[i])
          );
        }
        return item.value === val.value;
      });

      if (isValueIncluded) {
        onValueChange(
          value.filter((item) => {
            if (Array.isArray(item.value) && Array.isArray(val.value)) {
              return !(
                item.value.length === val.value.length &&
                item.value.every((v, i) => v === val.value[i])
              );
            }
            return item.value !== val.value;
          })
        );
      } else {
        onValueChange([...value, val]);
      }
    },
    [value, onValueChange]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const moveNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(
          nextIndex > value.length - 1 ? (loop ? 0 : -1) : nextIndex
        );
      };

      const movePrev = () => {
        const prevIndex = activeIndex - 1;
        setActiveIndex(prevIndex < 0 ? value.length - 1 : prevIndex);
      };

      if ((e.key === 'Backspace' || e.key === 'Delete') && value.length > 0) {
        if (inputValue.length === 0) {
          if (activeIndex !== -1 && activeIndex < value.length) {
            onValueChange(
              value.filter((item) => item.value !== value[activeIndex].value)
            );
            const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
            setActiveIndex(newIndex);
          } else {
            onValueChange(
              value.filter(
                (item) => item.value !== value[value.length - 1].value
              )
            );
          }
        }
      } else if (e.key === 'Enter') {
        setOpen(true);
      } else if (e.key === 'Escape') {
        if (activeIndex !== -1) {
          setActiveIndex(-1);
        } else {
          setOpen(false);
        }
      } else if (dir === 'rtl') {
        if (e.key === 'ArrowRight') {
          movePrev();
        } else if (e.key === 'ArrowLeft' && (activeIndex !== -1 || loop)) {
          moveNext();
        }
      } else if (e.key === 'ArrowLeft') {
        movePrev();
      } else if (e.key === 'ArrowRight' && (activeIndex !== -1 || loop)) {
        moveNext();
      }
    },
    [value, inputValue, activeIndex, loop, onValueChange, dir]
  );

  return (
    <MultiSelectContext.Provider
      value={{
        value,
        onValueChange: onValueChangeHandler,
        open,
        setOpen,
        inputValue,
        setInputValue,
        activeIndex,
        setActiveIndex,
      }}
    >
      <div
        onKeyDown={handleKeyDown}
        className={cn(
          'overflow-visible bg-transparent flex flex-col',
          className
        )}
        dir={dir}
        {...props}
      >
        {children}
      </div>
    </MultiSelectContext.Provider>
  );
};

const MultiSelectorTrigger = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const { value, onValueChange, activeIndex, setInputValue } = useMultiSelect();

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div className="flex flex-col gap-1">
      <div
        ref={ref}
        className={cn(
          'flex flex-wrap gap-1 p-1 py-2 border rounded-lg bg-background',
          className
        )}
        {...props}
      >
        {children}
      </div>
      {value?.length > 0 && (
        <div className="flex flex-wrap items-center gap-1">
          {value.map((item, index) => (
            <Badge
              key={item.value}
              className={cn(
                'px-1 rounded-xl flex items-center gap-1 bg-background border border-border rounded-md',
                activeIndex === index && 'ring-2 ring-muted-foreground '
              )}
              color="custom"
            >
              <span className="text-xs">{item.label}</span>
              <button
                aria-label={`Remove ${item.label} option`}
                aria-roledescription="button to remove option"
                type="button"
                onMouseDown={mousePreventDefault}
                onClick={() => onValueChange(item)}
              >
                <span className="sr-only">Remove {item.label} option</span>
                <X className="h-4 w-4 hover:stroke-destructive" />
              </button>
            </Badge>
          ))}
          <Button
            variant="outline"
            size="sm"
            onMouseDown={mousePreventDefault}
            onClick={() => {
              setInputValue('');
              onValueChange(null);
            }}
            className="text-sub-foreground"
          >
            <div className="flex items-center gap-1">
              <span>Clear all</span>
              <X className="h-4 w-4" />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
});

MultiSelectorTrigger.displayName = 'MultiSelectorTrigger';

const MultiSelectorInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => {
  const { setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } =
    useMultiSelect();
  return (
    <input
      {...props}
      ref={ref}
      value={inputValue}
      onChange={(e) => {
        if (activeIndex === -1) {
          setInputValue(e.target.value);
        }
      }}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      className={cn(
        'ml-2 text-sm bg-transparent outline-none text-foreground placeholder:text-muted-foreground flex-1',
        className,
        activeIndex !== -1 && 'caret-transparent'
      )}
    />
  );
});

MultiSelectorInput.displayName = 'MultiSelectorInput';

type MultiSelectorContentProps = {
  position?: 'top' | 'bottom';
} & React.HTMLAttributes<HTMLDivElement>;

const MultiSelectorContent = forwardRef<
  HTMLDivElement,
  MultiSelectorContentProps
>(({ children, position = 'bottom', ...props }, ref) => {
  const { open } = useMultiSelect();
  return (
    <div ref={ref} className="relative" {...props}>
      {open && children}
    </div>
  );
});

const MultiSelectorList = forwardRef<HTMLDivElement, MultiSelectorContentProps>(
  ({ className, position = 'bottom', children }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'p-2 text-sm text-foreground flex flex-col gap-2 rounded-md w-full absolute bg-background shadow-md z-10 border border-muted max-h-48 overflow-y-scroll',
          position === 'bottom' ? 'top-0' : 'bottom-full mb-10',
          className
        )}
      >
        {children ? (
          children
        ) : (
          <div className="text-muted-foreground">No results found</div>
        )}
      </div>
    );
  }
);

MultiSelectorList.displayName = 'MultiSelectorList';

const MultiSelectorItem = forwardRef<
  HTMLDivElement,
  { item: MultiSelectorItemValue } & React.HTMLAttributes<HTMLDivElement>
>(({ className, item, children, ...props }, ref) => {
  const { value: Options, onValueChange, setInputValue } = useMultiSelect();

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const isIncluded = Options?.some((val) => {
    if (Array.isArray(val.value) && Array.isArray(item.value)) {
      return (
        val.value.length === item.value.length &&
        val.value.every((v, i) => v === item.value[i])
      );
    }
    return val.value === item.value;
  });

  return (
    <div
      ref={ref}
      {...props}
      onClick={() => {
        onValueChange(item);
        setInputValue('');
      }}
      className={cn(
        'rounded-md cursor-pointer px-2 py-1 transition-colors flex justify-between',
        className,
        isIncluded && 'opacity-50 cursor-default',
        props.disabled && 'opacity-50 cursor-not-allowed'
      )}
      onMouseDown={mousePreventDefault}
    >
      {children}
      {item.description && (
        <span className="text-muted-foreground">{item.description}</span>
      )}
      {isIncluded && <Check className="h-4 w-4" />}
    </div>
  );
});

MultiSelectorItem.displayName = 'MultiSelectorItem';

export {
  MultiSelector,
  MultiSelectorTrigger,
  MultiSelectorInput,
  MultiSelectorContent,
  MultiSelectorList,
  MultiSelectorItem,
};
