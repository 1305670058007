import { Controller, useForm } from 'react-hook-form';
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from '~/core/ui/MultiSelect';
import Button from '~/core/ui/Button';
import Label from '~/core/ui/Label';
import { Trans } from 'react-i18next';
import type { DashbaordFilters } from '~/app/(workspace)/queries';
import { format } from 'date-fns';

const Filters = ({
  campaigns = [],
  agents = [],
  filters,
  applyFilters,
}: {
  campaigns: { label: string; value: string }[];
  agents: { label: string; value: string }[];
  applyFilters: (filters: DashbaordFilters) => void;
  filters: DashbaordFilters;
}) => {
  const { control, getValues, watch, reset } = useForm({
    defaultValues: {
      agents: filters?.agents,
      campaigns: filters?.campaigns,
    },
  });

  const watchedAgents = watch('agents');
  const watchedCampaigns = watch('campaigns');

  const handleApply = (filter: DashbaordFilters) => {
    const values = getValues();
    const dateRange = filter?.dateRange;
    const from = dateRange?.from
      ? format(new Date(dateRange.from), "yyyy-MM-dd'T'00:00:00")
      : null;
    const to = dateRange?.to
      ? format(new Date(dateRange.to), "yyyy-MM-dd'T'00:00:00")
      : null;

    const filters: DashbaordFilters = {
      campaigns:
        values.campaigns && values.campaigns.length > 0
          ? values.campaigns
          : null,
      agents: values.agents && values.agents.length > 0 ? values.agents : null,
      dateRange: {
        from: from,
        to: to,
      },
    };

    applyFilters(filters);
  };

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-col space-y-5">
        <div className="flex flex-col gap-2">
          <Label>
            <Trans i18nKey={'dashboard:filterModalCampaignsLabel'} />
          </Label>
          <Controller
            name="campaigns"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiSelector values={value ?? []} onValuesChange={onChange}>
                <MultiSelectorTrigger>
                  <MultiSelectorInput placeholder="Select campaigns" />
                </MultiSelectorTrigger>
                <MultiSelectorContent>
                  <MultiSelectorList>
                    {campaigns?.map((campaign) => (
                      <MultiSelectorItem key={campaign.label} item={campaign}>
                        <span className={'text-sm'}>{campaign.label}</span>
                      </MultiSelectorItem>
                    ))}
                    {campaigns?.length === 0 && (
                      <div className="flex items-center justify-center">
                        <span className={'text-sm'}>No campaigns found</span>
                      </div>
                    )}
                  </MultiSelectorList>
                </MultiSelectorContent>
              </MultiSelector>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label>
            <Trans i18nKey={'dashboard:filterModalAgentsLabel'} />
          </Label>
          <Controller
            name="agents"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiSelector values={value ?? []} onValuesChange={onChange}>
                <MultiSelectorTrigger>
                  <MultiSelectorInput placeholder="Select agents" />
                </MultiSelectorTrigger>
                <MultiSelectorContent>
                  <MultiSelectorList>
                    {agents?.map((agent) => (
                      <MultiSelectorItem key={agent.label} item={agent}>
                        <span className={'text-sm'}>{agent.label}</span>
                      </MultiSelectorItem>
                    ))}
                    {agents?.length === 0 && (
                      <div className="flex items-center justify-center">
                        <span className={'text-sm'}>No agents found</span>
                      </div>
                    )}
                  </MultiSelectorList>
                </MultiSelectorContent>
              </MultiSelector>
            )}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          variant="outline"
          disabled={!(watchedAgents?.length || watchedCampaigns?.length)}
          onClick={() => {
            reset({
              agents: [],
              campaigns: [],
            });
            handleApply(filters);
          }}
        >
          <Trans i18nKey={'dashboard:filterModalResetButton'} />
        </Button>
        <Button onClick={() => handleApply(filters)}>
          <Trans i18nKey={'dashboard:filterModalApplyButton'} />
        </Button>
      </div>
    </div>
  );
};

export default Filters;
