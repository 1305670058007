import { Avatar, AvatarFallback } from '~/core/ui/Avatar';
import { AVATARCOLORS } from '~/core/ui/Avatar/AvatarColorsConstants';
import Badge from '~/core/ui/Badge';
import Button from '~/core/ui/Button';
import { Card, CardContent } from '~/core/ui/Card';
import LinkedinLogo from '~/core/ui/Logo/LinkedinLogo';
import Trans from '~/core/ui/Trans';
import { ActivityType, type LatestRepliesOutput } from '@topo-io/client';
import Link from 'next/link';
import { getPath } from '~/core/routes/get-path';
import configuration from '~/configuration';
import { formatDate } from 'date-fns/format';
import { CornerUpLeft } from '@untitled-ui/icons-react';

const paths = configuration.paths;
const LatestReplies = ({
  data,
}: {
  data: LatestRepliesOutput[];
}) => {
  return (
    <Card className="w-full h-[456px]">
      <CardContent className="flex flex-col space-y-6 px-6 pt-6 h-full">
        <div className="flex items-center justify-between gap-1 text-card-foreground">
          <span className="text-lg font-semibold flex items-center gap-1.5">
            <Trans i18nKey={'dashboard:latestRepliesHeading'} />
          </span>
          <Link
            href={getPath(paths.activityFeed, {
              activity_type: ActivityType.MESSAGE_REPLIED,
            })}
          >
            <Button variant="outline">
              <Trans i18nKey={'dashboard:viewMore'} />
            </Button>
          </Link>
        </div>

        <div className="flex flex-col space-y-3 h-full">
          {data.map((reply, index) => (
            <div
              key={reply.email + index.toString()}
              className="flex p-2 space-x-3"
            >
              <div>
                <Avatar className="!w-10 !h-10">
                  <AvatarFallback
                    className={`${AVATARCOLORS[index % AVATARCOLORS.length]} p-4 !text-foreground`}
                  >
                    {(reply.firstName + reply.lastName)
                      .split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-grow">
                <div className="flex flex-col gap-0.5 w-full">
                  <div className="text-sm font-medium flex items-center gap-2">
                    <Link
                      href={getPath(configuration.paths.leads.contacted, {
                        contactId: reply.contactId,
                        campaignId: reply.campaignId,
                      })}
                    >
                      <span>
                        {reply.firstName} {reply.lastName}
                      </span>
                    </Link>
                    {reply.linkedinUrl ? (
                      <a
                        href={reply.linkedinUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinLogo className="h-[15px] w-[15px] text-primary" />
                      </a>
                    ) : null}
                  </div>
                  <Badge
                    color="custom"
                    className="border border-border text-xs leading-normal font-medium text-foreground rounded-md flex justify-center items-center w-fit px-1.5 py-[0.5px]"
                    size="custom"
                  >
                    <Trans
                      i18nKey={`email:${reply.replyCategory.toLowerCase()}`}
                    />
                  </Badge>
                </div>
                <div className="flex flex-col gap-1 ml-auto whitespace-nowrap">
                  <span className="text-sm text-muted-foreground">
                    {formatDate(reply.repliedAt, 'MMM d, yyyy')}
                  </span>
                  <span className="text-xs text-sub-foreground text-right">
                    {formatDate(reply.repliedAt, 'h:mm a')}
                  </span>
                </div>
              </div>
            </div>
          ))}

          {data.length === 0 && (
            <div className="text-sm text-sub-foreground flex flex-col justify-center items-center gap-4 h-full">
              <Avatar className="!h-12 !w-12 bg-slate-100 dark:bg-slate-800 flex items-center justify-center text-sidebar-ring">
                <CornerUpLeft className="h-6 w-6" />
              </Avatar>
              <Trans i18nKey={'dashboard:noReplies'} />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LatestReplies;
