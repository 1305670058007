'use client';

import { AnalyticsLeads } from '~/app/(workspace)/components/AnalyticsLeads';
import {
  type DashbaordFilters,
  type DashboardDateRange,
  getAgents,
  getCampaigns,
} from '~/app/(workspace)/queries';
import { Skeleton } from '~/core/ui/Skeleton';
import CustomFilters from '~/app/(workspace)/components/CustomFilters';
import { useCallback, useEffect, useState } from 'react';
import { useTopoClient } from '~/core/hooks/use-topo-client';
import LatestHotLeads from '~/app/(workspace)/components/LatestHotLeads';
import LatestReplies from '~/app/(workspace)/components/LatestReplies';
import { differenceInDays, subDays, format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { cn } from '~/core/utils/shadcn-utils';

type FiltersData = {
  campaigns: { label: string; value: string }[];
  agents: { label: string; value: string }[];
};

const Dashboard = ({
  organizationId,
  className,
  selectedCampaignId,
  newComponentInFilters,
}: {
  organizationId: string;
  className?: string;
  selectedCampaignId?: string;
  newComponentInFilters?: React.ReactNode;
}) => {
  const [filtersData, setFiltersData] = useState<FiltersData>({
    campaigns: [],
    agents: [],
  });
  const [filters, setFilters] = useState<DashbaordFilters>({
    dateRange: {
      from: format(subDays(new Date(), 30), "yyyy-MM-dd'T'00:00:00"),
      to: format(new Date(), "yyyy-MM-dd'T'23:59:59"),
    },
    campaigns: selectedCampaignId
      ? [{ label: selectedCampaignId, value: selectedCampaignId }]
      : [],
    agents: [],
  });
  const [selectedRange, setSelectedRange] = useState<
    '30d' | '3m' | '1y' | 'custom'
  >('30d');

  const topoClient = useTopoClient();
  const staleTime = 5 * 60 * 1000;
  const gcTime = 10 * 60 * 1000;
  const isOrganizationAndFiltersValid = !!organizationId && !!filters;

  const { data: currentMetricsData, isLoading: currentMetricsLoading } =
    useQuery({
      queryKey: ['metrics', organizationId, filters],
      queryFn: async () => {
        const results = await getMetricsData(organizationId, filters);
        return results;
      },
      enabled: isOrganizationAndFiltersValid,
      staleTime,
      gcTime,
    });

  const { data: lastPeriodMetricsData, isLoading: lastPeriodMetricsLoading } =
    useQuery({
      queryKey: ['metricsForLastPeriod', organizationId, filters],
      queryFn: () => getMetricsDataForLastPeriod(organizationId, filters),
      enabled: isOrganizationAndFiltersValid,
      staleTime,
      gcTime,
    });

  const { data: emailsSentData } = useQuery({
    queryKey: ['emailsSent', organizationId, filters],
    queryFn: () => getMessagesSentData(organizationId, filters),
    enabled: isOrganizationAndFiltersValid,
    staleTime,
    gcTime,
  });

  const { data: latestHotLeadsData, isLoading: latestHotLeadsLoading } =
    useQuery({
      queryKey: ['latestHotLeads', organizationId, filters],
      queryFn: () => getLatestHotLeadsData(organizationId, filters),
      enabled: isOrganizationAndFiltersValid,
      staleTime,
      gcTime,
    });

  const { data: latestRepliesData, isLoading: latestRepliesLoading } = useQuery(
    {
      queryKey: ['latestReplies', organizationId, filters],
      queryFn: () => getLatestRepliesData(organizationId, filters),
      enabled: isOrganizationAndFiltersValid,
      staleTime,
      gcTime,
    }
  );

  const { data: leadsContactedData } = useQuery({
    queryKey: ['leadsContacted', organizationId, filters],
    queryFn: () => getLeadsContactedData(organizationId, filters),
    enabled: isOrganizationAndFiltersValid,
    staleTime,
    gcTime,
  });

  const { data: positiveRepliesData } = useQuery({
    queryKey: ['positiveReplies', organizationId, filters],
    queryFn: () => getPositiveRepliesData(organizationId, filters),
    enabled: isOrganizationAndFiltersValid,
    staleTime,
    gcTime,
  });

  const getMetricsData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getMetricsPerOrganization(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getMetricsDataForLastPeriod = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    const lastPeriodDateRange = getLastPeriodDateRange(filter?.dateRange);

    if (!lastPeriodDateRange) {
      return {
        engagedLeads: 0,
        openedMessages: 0,
        repliedMessages: 0,
        sentMessages: 0,
      };
    }

    return await topoClient.metrics.getMetricsPerOrganization(organizationId, {
      startDate: lastPeriodDateRange.from,
      endDate: lastPeriodDateRange.to,
      agents: filter?.agents?.map((agent) => agent.value),
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
    });
  };

  const getLastPeriodDateRange = (
    dateRange: DashboardDateRange | null | undefined
  ) => {
    if (!dateRange) {
      return null;
    }

    if (!(dateRange.from && dateRange.to)) {
      return null;
    }

    const dateDifference = differenceInDays(dateRange.to, dateRange.from);
    const startDate = subDays(dateRange.from, dateDifference);
    const endDate = subDays(dateRange.to, dateDifference);
    return {
      from: format(startDate, "yyyy-MM-dd'T'00:00:00"),
      to: format(endDate, "yyyy-MM-dd'T'23:59:59"),
    };
  };

  const getMessagesSentData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getMessagesSent(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getLeadsContactedData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getLeadsContacted(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getPositiveRepliesData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getPositiveReplies(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getLatestHotLeadsData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getLatestHotLeads(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getLatestRepliesData = async (
    organizationId: string,
    filter: DashbaordFilters
  ) => {
    return await topoClient.metrics.getLatestReplies(organizationId, {
      startDate: filter?.dateRange?.from,
      endDate: filter?.dateRange?.to,
      campaigns: filter?.campaigns?.map((campaign) => campaign.value),
      agents: filter?.agents?.map((agent) => agent.value),
    });
  };

  const getFiltersData = useCallback(async () => {
    const campaigns = await getCampaigns(organizationId);
    const agents = await getAgents(organizationId);
    setFiltersData({ campaigns, agents });
  }, [organizationId]);

  useEffect(() => {
    getFiltersData();
  }, [getFiltersData]);

  const handleApplyFilters = (filters: DashbaordFilters) => {
    setFilters(filters);
  };

  const shouldExcludeIncompleteWeek = (
    range: '30d' | '3m' | '1y' | 'custom'
  ) => {
    return ['30d', '3m', '1y'].includes(range);
  };

  const handleRangeChange = (range: '30d' | '3m' | '1y' | 'custom') => {
    setSelectedRange(range);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-4 pb-6 w-full px-[1.25rem] py-3',
        className
      )}
    >
      <div className="flex items-center justify-between">
        {newComponentInFilters}
        <CustomFilters
          filters={filters}
          filtersData={filtersData}
          handleApplyFilters={handleApplyFilters}
          handleRangeChange={handleRangeChange}
          hideExtraFilters={!!newComponentInFilters}
        />
      </div>
      {(currentMetricsLoading || lastPeriodMetricsLoading) && (
        <div className="flex h-full w-full flex-col gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Skeleton className="h-[336px] w-full" />
            <Skeleton className="h-[336px] w-full" />
            <Skeleton className="h-[336px] w-full" />
          </div>
        </div>
      )}
      {organizationId &&
        !currentMetricsLoading &&
        currentMetricsData &&
        lastPeriodMetricsData && (
          <>
            <AnalyticsLeads
              leads={currentMetricsData.engagedLeads}
              leadsForLastPeriod={lastPeriodMetricsData.engagedLeads}
              opened={currentMetricsData.openedMessages}
              replied={currentMetricsData.repliedMessages.overallRepliedMessages}
              repliedForLastPeriod={0}
              positiveReplied={
                currentMetricsData.repliedMessages.positiveRepliedMessages
              }
              hotLeads={currentMetricsData.hotLeads}
              sent={currentMetricsData.sentMessages}
              sentForLastPeriod={lastPeriodMetricsData.sentMessages}
              leadsContacted={leadsContactedData ?? []}
              emailsSent={emailsSentData ?? []}
              replies={positiveRepliesData ?? []}
              excludeIncompleteWeek={shouldExcludeIncompleteWeek(selectedRange)}
            />
          </>
        )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {latestHotLeadsLoading && <Skeleton className="h-[456px] w-full" />}
        {latestRepliesLoading && <Skeleton className="h-[456px] w-full" />}
        {latestHotLeadsData && !latestHotLeadsLoading && (
          <LatestHotLeads data={latestHotLeadsData} />
        )}
        {latestRepliesData && !latestRepliesLoading && (
          <LatestReplies data={latestRepliesData} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
