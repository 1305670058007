'use client';
import type { ChartDataOutput } from '@topo-io/client';
import {
  CornerUpLeft,
  Flash,
  Mail04,
  TrendDown01,
  TrendUp01,
} from '@untitled-ui/icons-react';
import DataChart from '~/app/(workspace)/components/DataChart';
import Badge from '~/core/ui/Badge';
import { Card, CardContent } from '~/core/ui/Card';
import Trans from '~/core/ui/Trans';

interface AnalyticsLeadsProps {
  leads: number;
  leadsForLastPeriod: number;
  opened: number;
  replied: number;
  repliedForLastPeriod: number;
  positiveReplied: number;
  hotLeads: number;
  sent: number;
  sentForLastPeriod: number;
  leadsContacted: ChartDataOutput[];
  emailsSent: ChartDataOutput[];
  replies: ChartDataOutput[];
  excludeIncompleteWeek?: boolean;
}

export function AnalyticsLeads({
  leads,
  leadsForLastPeriod,
  opened,
  replied,
  repliedForLastPeriod,
  positiveReplied,
  hotLeads,
  sent,
  sentForLastPeriod,
  leadsContacted,
  emailsSent,
  replies,
  excludeIncompleteWeek = false,
}: AnalyticsLeadsProps) {
  const calculateDeltaPercentage = (oldValue: number, newValue: number) => {
    if (oldValue === 0) {
      return newValue > 0 ? 100 : 0; // Assuming 100% increase if oldValue is 0 and newValue is positive
    }
    return ((newValue - oldValue) / oldValue) * 100;
  };

  const deltaLeadsPercent = calculateDeltaPercentage(leadsForLastPeriod, leads);
  const deltaSentPercent = calculateDeltaPercentage(sentForLastPeriod, sent);
  const deltaRepliedPercent = calculateDeltaPercentage(
    repliedForLastPeriod,
    replied
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      <AnalyticsDataCard
        title={<Trans i18nKey="emails:leadContacted" />}
        primaryValue={leads}
        secondaryLabel={<Trans i18nKey="emails:hotLeads" />}
        secondaryValue={hotLeads}
        deltaPercent={deltaLeadsPercent}
        icon={<Flash className="h-5 text-sidebar-sub-foreground" />}
        chartData={leadsContacted}
        excludeIncompleteWeek={excludeIncompleteWeek}
      />

      <AnalyticsDataCard
        title={<Trans i18nKey="emails:sent" />}
        primaryValue={sent}
        secondaryLabel={<Trans i18nKey="emails:openRate" />}
        secondaryValue={opened > 0 ? (opened / sent) * 100 : 0}
        isSecondaryValuePercentage={true}
        deltaPercent={deltaSentPercent}
        icon={<Mail04 className="h-5 text-sidebar-sub-foreground" />}
        chartData={emailsSent}
        excludeIncompleteWeek={excludeIncompleteWeek}
      />

      <AnalyticsDataCard
        title={<Trans i18nKey="emails:positiveReplied" />}
        primaryValue={positiveReplied}
        secondaryLabel={<Trans i18nKey="emails:positiveReplyRate" />}
        secondaryValue={
          positiveReplied > 0 ? (positiveReplied / leads) * 100 : 0
        }
        isSecondaryValuePercentage={true}
        deltaPercent={deltaRepliedPercent}
        icon={<CornerUpLeft className="h-5 text-sidebar-sub-foreground" />}
        chartData={replies}
        excludeIncompleteWeek={excludeIncompleteWeek}
      />
    </div>
  );
}

const AnalyticsDataCard = ({
  title,
  primaryValue,
  secondaryLabel,
  secondaryValue,
  isSecondaryValuePercentage = false,
  deltaPercent,
  icon,
  chartData,
  excludeIncompleteWeek = false,
}: {
  title: React.ReactNode;
  primaryValue: number;
  secondaryLabel: React.ReactNode;
  secondaryValue: number;
  isSecondaryValuePercentage?: boolean;
  deltaPercent: number;
  icon: React.ReactNode;
  chartData: ChartDataOutput[];
  excludeIncompleteWeek: boolean;
}) => {
  return (
    <Card className="w-full px-4 pt-5 pb-2">
      <CardContent className="flex flex-row p-0 items-center">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center gap-1">
            {icon}
            <div className="flex w-full justify-between text-sub-foreground text-sm leading-6 items-center font-medium">
              {title}
              {deltaPercent !== 0 && (
                <Badge
                  color={deltaPercent >= 0 ? 'success' : 'error'}
                  size="small"
                  className="flex items-center gap-1 rounded-2xl px-1"
                >
                  {deltaPercent >= 0 ? (
                    <TrendUp01 className="h-4 w-4" />
                  ) : (
                    <TrendDown01 className="h-4 w-4" />
                  )}
                  {Math.abs(deltaPercent).toFixed(2)}%
                </Badge>
              )}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <span className="font-medium leading-normal text-4xl text-card-foreground">
              {primaryValue}
            </span>
            {secondaryValue > 0 && (
              <span className="text-sm font-normal leading-6 text-sub-foreground">
                {isSecondaryValuePercentage
                  ? `${secondaryValue.toFixed(2)}%`
                  : secondaryValue}{' '}
                {secondaryLabel}
              </span>
            )}
          </div>
          {chartData.length > 0 && (
            <DataChart
              data={chartData}
              excludeIncompleteWeek={excludeIncompleteWeek}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
