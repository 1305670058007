'use client';

import { Area, AreaChart, XAxis, YAxis } from 'recharts';

import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '~/core/ui/Chart';

interface DataPoint {
  date: string;
  count: number;
}

interface DataChartProps {
  data: DataPoint[];
  excludeIncompleteWeek?: boolean;
}

const chartConfig = {
  count: {
    label: 'Count',
    color: 'hsl(var(--base-primary))',
  },
} satisfies ChartConfig;

const TIME_CONSTANTS = {
  MILLISECONDS_PER_DAY: 1000 * 60 * 60 * 24,
  DAYS_IN_WEEK: 7,
  SUNDAY_INDEX: 0,
  MONDAY_OFFSET: 1,
  SUNDAY_OFFSET: -6,
  LONG_PERIOD_THRESHOLD: 60,
  MEDIUM_PERIOD_THRESHOLD: 14,
} as const;

function aggregateDataByPeriod(
  data: DataPoint[],
  period: 'day' | 'week' | 'month',
  excludeIncompleteWeek: boolean
) {
  const groupedData = new Map<string, number>();
  const today = new Date();

  for (const point of data) {
    const date = new Date(point.date);
    let key: string;
    let skipPoint = false;

    switch (period) {
      case 'week': {
        const day = date.getUTCDay();
        const mondayOffset =
          day === TIME_CONSTANTS.SUNDAY_INDEX
            ? TIME_CONSTANTS.SUNDAY_OFFSET
            : TIME_CONSTANTS.MONDAY_OFFSET;
        const diff = date.getUTCDate() - day + mondayOffset;
        const monday = new Date(date);
        monday.setUTCDate(diff);

        if (excludeIncompleteWeek) {
          const nextMonday = new Date(monday);
          nextMonday.setUTCDate(
            monday.getUTCDate() + TIME_CONSTANTS.DAYS_IN_WEEK
          );
          if (nextMonday > today) {
            skipPoint = true;
          }
        }

        key = monday.toISOString().split('T')[0];
        break;
      }
      case 'month':
        key = date.toISOString().slice(0, 7);
        break;
      default:
        key = date.toISOString().split('T')[0];
    }

    if (!skipPoint) {
      groupedData.set(key, (groupedData.get(key) || 0) + point.count);
    }
  }

  const result = Array.from(groupedData.entries())
    .map(([date, count]) => ({
      date,
      count,
    }))
    .sort((a, b) => a.date.localeCompare(b.date));

  if (result.length === 1) {
    const singlePoint = result[0];
    const previousDate = new Date(singlePoint.date);

    switch (period) {
      case 'week': {
        previousDate.setUTCDate(
          previousDate.getUTCDate() - TIME_CONSTANTS.DAYS_IN_WEEK
        );
        break;
      }
      case 'month': {
        previousDate.setUTCMonth(previousDate.getUTCMonth() - 1);
        break;
      }
      default: {
        previousDate.setUTCDate(previousDate.getUTCDate() - 1);
      }
    }

    const previousDateStr =
      period === 'month'
        ? previousDate.toISOString().slice(0, 7)
        : previousDate.toISOString().split('T')[0];

    result.unshift({
      date: previousDateStr,
      count: 0,
    });
  }

  return result;
}

function determineAggregationPeriod(
  data: DataPoint[]
): 'day' | 'week' | 'month' {
  const firstDate = new Date(data[0].date);
  const lastDate = new Date(data[data.length - 1].date);
  const daysDiff =
    (lastDate.getTime() - firstDate.getTime()) /
    TIME_CONSTANTS.MILLISECONDS_PER_DAY;

  if (daysDiff > TIME_CONSTANTS.LONG_PERIOD_THRESHOLD) {
    return 'month';
  }
  if (daysDiff > TIME_CONSTANTS.MEDIUM_PERIOD_THRESHOLD) {
    return 'week';
  }
  return 'day';
}

export default function DataChart({
  data,
  excludeIncompleteWeek = false,
}: DataChartProps) {
  const period = determineAggregationPeriod(data);
  const aggregatedData = aggregateDataByPeriod(
    data,
    period,
    excludeIncompleteWeek
  );

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    switch (period) {
      case 'week':
        return `Week of ${dateObj.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })}`;
      case 'month':
        return dateObj.toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric',
        });
      default:
        return dateObj.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
    }
  };

  return (
    <div className="w-full">
      <ChartContainer config={chartConfig}>
        <AreaChart
          accessibilityLayer
          data={aggregatedData}
          margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
          className="aspect-auto w-full"
        >
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent labelFormatter={formatDate} />}
          />
          <defs>
            <linearGradient id="fillCount" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="var(--color-count)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="var(--color-count)"
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" axisLine={false} tickLine={false} hide={true} />
          <YAxis
            domain={[0, 'auto']}
            hide={true}
            allowDataOverflow={false}
            scale="auto"
          />
          <Area
            dataKey="count"
            type="natural"
            fill="url(#fillCount)"
            fillOpacity={0.4}
            stroke="var(--color-count)"
            stackId="a"
            baseLine={0}
            isAnimationActive={false}
          />
        </AreaChart>
      </ChartContainer>
    </div>
  );
}
