import Filters from '~/app/(workspace)/components/Filters';
import type { DashbaordFilters } from '~/app/(workspace)/queries';
import { Card, CardContent, CardHeader, CardTitle } from '~/core/ui/Card';
import Trans from '~/core/ui/Trans';
const FilterModal = ({
  campaigns,
  agents,
  filters,
  applyFilters,
}: {
  campaigns: { label: string; value: string }[];
  agents: { label: string; value: string }[];
  filters: DashbaordFilters;
  applyFilters: (filters: DashbaordFilters) => void;
}) => {
  return (
    <Card className="w-full p-6 space-y-5">
      <CardHeader className="p-0">
        <CardTitle className="font-semibold">
          <Trans i18nKey="dashboard:filterModalHeading" />
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <Filters
          campaigns={campaigns}
          agents={agents}
          applyFilters={applyFilters}
          filters={filters}
        />
      </CardContent>
    </Card>
  );
};

export default FilterModal;
